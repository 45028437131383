export const action = {
    Id: 'ODcqWQcBAAAAHnLYWVKgGUNvrvnl+p/l730='
}

export const actionType = {
    Desc: 'Scan',
    Id: 'ODcqWQcBAAAAHo2XJQhXDlEVikaz8gweU5M='
}

export const benefit = {
    Id: 'ODcqWQcBAAAAHgb/paW56EISl+Cm8vRv6e4='
}

export const benefitType = {
    Desc: 'Modellieren',
    Id: 'ODcqWQcBAAAAHnbZ7N56zlFQsgadmi7Koy4='
}

export const scaDataInfo = {
    Id: '',
    MimeName: 'application/vnd.cadenas.pcs.sca-v6',
    Role: 'eDocumentRole::SCA'
}

export const histDataInfo = {
    Id: '',
    MimeName: 'application/vnd.cadenas.pcs.history-v7+xml',
    Role: 'eDocumentRole::HISTORY'
}

export const customer = {
    ActionIds: [
        'ODcqWQcBAAAAHgNIxPr0k0PLh4GYS+p/4zY='
    ],
    AdditionalData: [
        '',
        '',
        ''
    ],
    Address: {
        City: '',
        Country: '',
        PostalCode: '',
        State: '',
        Street: ''
    },
    Category: '',
    Contact: {
        EMail: '',
        Phone: [
            '',
            ''
        ]
    },
    CreatedDb: '20200206T085057.018Z',
    CreatedLogically: '20200206T085036.793Z',
    DateOfBirth: '',
    Doctor: '',
    DocumentationInfo: [
    ],
    FamilyName: '',
    FirstName: '',
    Gender: 'Gender::UNKNOWN',
    HealthInsurance: {
        Id: '',
        Name: ''
    },
    Id: 'ODcqWQcBAAAAHhSnR/fh9ECVkE0wcOghViQ=',
    Imported: '',
    ModifiedDb: '20210308T161252.403Z',
    ModifiedLogically: '20210308T161252.345Z',
    Note: '',
    Original: {
        BranchOfficeId: 67838,
        CustomerId: 1100,
        DbId: 10
    },
    SizeOfShoes: '',
    XFiles: [
    ]
}

export const xml = `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE PCSHistory[]>
<PCSHistory documentversion="0.1" side="1">
  <KernelData randx="30" ynum="999" yres="0.3" xres="0.3" randy="30" xnum="496" />
  <Blank>
    <metadata type="company">Paromed GmbH &amp; Co KG</metadata>
    <metadata type="revision">1.0</metadata>
    <metadata type="name">CL-Line</metadata>
    <metadata type="description">CL-Line</metadata>
    <metadata lang="de" type="description">EVA-Comfort-Line Rohling A30-50 33mm turquoise-grey</metadata>
    <metadata lang="en" type="description">CL-Line</metadata>
    <metadata lang="fr" type="description">CL-Line</metadata>
    <metadata type="id">101631</metadata>
    <metadata type="title">CL-RB</metadata>
    <metadata lang="de" type="title">CL-RB</metadata>
    <metadata type="typestring">CL-RB-2EVA-30-50-33, 3, L</metadata>
    <metadata type="typename">CL</metadata>
    <metadata type="typeform">RB</metadata>
    <metadata type="typematerial">2EVA</metadata>
    <metadata type="typeshore">30-50</metadata>
    <metadata type="typeheight">33</metadata>
    <metadata type="size">3</metadata>
    <metadata type="side">L</metadata>
    <metadata type="holder">130,230,330</metadata>
    <metadata type="bbox">126,340,33</metadata>
    <metadata type="heelpoint">63,50,0</metadata>
    <metadata type="note">CL-Rohling</metadata>
  </Blank>
  <MarkerPlantar>
    <ptoe y="269.1395503182226" x="75.79842295229135" />
    <pheel y="29.85000038146973" x="70.65000152587891" />
    <pheelinner y="69.80000305175781" x="109.5951282978528" />
    <pheelouter y="69.80000305175781" x="38.96951437365168" />
    <prearinner y="92.96201611163926" x="111.2899115664071" />
    <prearouter y="127.7737042010609" x="34.17265894830637" />
    <pballinner y="201.75" x="118.3500061035156" />
    <pballouter y="178.6499938964844" x="29.85000038146973" />
    <maxl y="109.8000030517578" x="112.2000045776367" />
    <rootbased value="1" />
  </MarkerPlantar>
  <Elements>
    <Function variant="7" name="Blank &lt;CL-RB-2EVA-30-50-33, 3, L&gt;" type="HGloba">
      <useminz value="1" />
      <minz value="1.3" />
      <fillneg value="0" />
      <irp value="1" />
      <param encoding="base64"><![CDATA[AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABABAAAAAAAAD/wAAAAAAAAP/AAAAAAAAA/8AAAAAAAAEBShmaAAAAAQGK7M0AAAAAAAAAAAAAAAA==]]></param>
    </Function>
  </Elements>
</PCSHistory>
`
