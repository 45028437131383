// import UserService from './userService'

import * as printsoleDiagnosis from './data/printsole/diagnosis'
import * as vcwDiagnosis from './data/vcw/diagnosis'

import * as printsoleFitsSpecification from './data/printsole/fitsSpecification'
import * as fitsComfortFitsSpecification from './data/fits-comfort/fitsSpecification'
import * as vcwFitsSpecification from './data/vcw/fitsSpecification'

import * as printsoleSoleAddition from './data/printsole/soleAddition'
import * as vcwSoleAddition from './data/vcw/soleAddition'

import * as printsoleSoleResult from './data/printsole/soleResult'
import * as vcwSoleResult from './data/vcw/soleResult'

import * as printsoleSoleSpecification from './data/printsole/soleSpecification'
import * as kievitOrthoSoleSpecification from './data/kievit-ortho/soleSpecification'
import * as vcwSoleSpecification from './data/vcw/soleSpecification'

class DataProvider {
    /**
     * Constructor function
     */
    constructor () {
        this.folder = 'vcw'
    }

    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get diagnosis () {
        let diagnosis = null
        switch (this.folder) {
        case 'printsole':
            diagnosis = printsoleDiagnosis
            break
        default:
            diagnosis = vcwDiagnosis
            break
        }
        return diagnosis
    }

    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get fitsSpecification () {
        let fitsSpecification = null
        switch (this.folder) {
        case 'printsole':
            fitsSpecification = printsoleFitsSpecification
            break
        case 'fits-comfort':
            fitsSpecification = fitsComfortFitsSpecification
            break
        default:
            fitsSpecification = vcwFitsSpecification
            break
        }
        return fitsSpecification
    }

    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get soleAddition () {
        let soleAddition = null
        switch (this.folder) {
        case 'printsole':
            soleAddition = printsoleSoleAddition
            break
        default:
            soleAddition = vcwSoleAddition
            break
        }
        return soleAddition
    }

    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get soleResult () {
        let soleResult = null
        switch (this.folder) {
        case 'printsole':
            soleResult = printsoleSoleResult
            break
        default:
            soleResult = vcwSoleResult
        }
        return soleResult
    }

    /**
     * Get the diagnosis
     *
     * @returns a pallete
     */
    get soleSpecification () {
        let soleSpecification = null
        switch (this.folder) {
        case 'printsole':
            soleSpecification = printsoleSoleSpecification
            break
        case 'Kievit Orthopedie':
            soleSpecification = kievitOrthoSoleSpecification
            break
        default:
            soleSpecification = vcwSoleSpecification
        }
        return soleSpecification
    }

    /**
     *
     * @param {*} value
     */
    setFolder (value) {
        this.folder = value
    }

    /**
     *
     * @returns
     */
    getFolder () {
        return this.folder
    }
}

 
export default new DataProvider()
