import { createSlice } from '@reduxjs/toolkit'
import {
    validateAllFields,
    validateGroundSole,
    validateSize,
    validateBroadenedFront,
    validateBlock,
    validateMillingSize,
    validateCoverMaterial,
    validateSoleThickness,
    validateCoverLevel
} from './validation.js'
import { initialState } from './initialState.js'

const soleSpecification = createSlice({
    name: 'soleSpec',
    initialState: [
        { ...initialState },
        { ...initialState },
        { ...initialState }
    ],
    reducers: {
        soleData (state, action) {
            const soleNr = action.payload.soleSpecNumber
            if (soleNr !== undefined) {
                soleDataFunc(state, action)
            } else {
                state.forEach((element, i) => {
                    action.payload.soleSpecNumber = i
                    soleDataFunc(state, action)
                })
            }
        },
        soleAccordion (state, action) {
            const soleNr = action.payload.soleSpecNumber
            switch (action.payload.key) {
            case 'accordion':
                state[soleNr].soleAccordion.accordion = action.payload.value
                if (!state[soleNr].soleAccordion.hasBeenOpened) {
                    state[soleNr].soleAccordion.hasBeenOpened = action.payload.value
                } else {
                    validateAllFields(state, soleNr)
                }
                break
            case 'validate':
                state[soleNr].soleAccordion.validate = action.payload.value
                break
            case 'hasBeenOpened':
                state[soleNr].soleAccordion.validate = action.payload.value
                state.generalAccordion.hasBeenOpened = true
                break
            }
        },
        soleThickness (state) {
            state.forEach((element, i) => {
                validateSoleThickness(state, i)
                validateAllFields(state, i)
            })
        },
        importSole (state, action) {
            importSoleFunc(state, action)
        },
        resetSole (state, action) {
            state.forEach((element, i) => {
                const soleNr = i
                state[soleNr].soleData = action.payload.soleData
                state[soleNr].soleErrors = action.payload.soleErrors
            })
        },
        closeSoles (state) {
            state.forEach((element, i) => {
                const soleNr = i
                state[soleNr].soleAccordion.accordion = false
                state[soleNr].soleAccordion.hasBeenOpened = true
                validateAllFields(state, soleNr)
            })
        }
    }
})

/**
 * Set sole data function
 *
 * @param {Obejct} state - current redux state.
 * @param {Object} action - action containing data.
 */
export function importSoleFunc (state, action) {
    state.forEach((element, i) => {
        const soleNr = i
        if (action.payload.soleSpecification[soleNr] !== undefined && action.payload.soleSpecification[soleNr].soleData !== undefined) {
            state[soleNr].soleData = action.payload.soleSpecification[soleNr].soleData
        } else if (action.payload.soleSpecification[soleNr] !== undefined) {
            state[soleNr].soleData = action.payload.soleSpecification[soleNr]
        }
        validateSoleThickness(state, soleNr)
        validateAllFields(state, soleNr)
    })

    return state
}

/**
 * Set sole data function
 *
 * @param {Object} state - current redux state.
 * @param {Object} action - action containing data.
 */
function soleDataFunc (state, action) {
    const soleNr = action.payload.soleSpecNumber
    const value = action.payload.value
    const key = action.payload.key

    switch (key) {
    case 'millingSize':
        state[soleNr].soleData.millingSize = value
        validateMillingSize(state, soleNr, value)
        validateBlock(state, soleNr, action)
        break
    case 'block':
        state[soleNr].soleData.block = value
        validateGroundSole(state, value, soleNr)
        validateSize(state, soleNr, state[soleNr].soleData.footSizeLeft, true, false)
        validateSize(state, soleNr, state[soleNr].soleData.footSizeRight, true, true)
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeLeft, false, false)
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeRight, false, true)
        validateBroadenedFront(state, soleNr, state[soleNr].soleData.broadenedFront)
        validateBlock(state, soleNr, action)
        break
    case 'coverMaterial':
        state[soleNr].soleData.coverMaterial = value
        validateCoverMaterial(state, soleNr, value)
        break
    case 'coverLevel':
        state[soleNr].soleData.coverLevel = value
        validateCoverLevel(state, soleNr, value)
        // validateTopCoverLayer(state, action.payload.addition, value, soleNr)
        break
    case 'footSizeLeft':
        state[soleNr].soleData.footSizeLeft = value
        validateSize(state, soleNr, value, true, false)
        break
    case 'footSizeRight':
        state[soleNr].soleData.footSizeRight = value
        validateSize(state, soleNr, value, true, true)
        break
    case 'soleSizeLeft':
        state[soleNr].soleData.soleSizeLeft = value
        validateSize(state, soleNr, value, false, false)
        validateBroadenedFront(state, soleNr, state[soleNr].soleData.broadenedFront)
        break
    case 'soleSizeRight':
        state[soleNr].soleData.soleSizeRight = value
        validateSize(state, soleNr, value, false, true)
        validateBroadenedFront(state, soleNr, state[soleNr].soleData.broadenedFront)
        validateAllFields(state, soleNr)
        break
    case 'groundSole':
        state[soleNr].soleData.groundSole = value
        validateGroundSole(state, value, soleNr)
        validateBroadenedFront(state, soleNr, state[soleNr].soleData.broadenedFront)
        validateSize(state, soleNr, state[soleNr].soleData.footSizeLeft, true, false)
        validateSize(state, soleNr, state[soleNr].soleData.footSizeRight, true, true)
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeLeft, false, false)
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeRight, false, true)
        break
    case 'broadenedFront':
        state[soleNr].soleData.broadenedFront = value
        validateBroadenedFront(state, soleNr, value)
        break
    case 'leftSoleThickness':
        state[soleNr].soleData.leftSoleThickness = value
        state[soleNr].soleData.totalLeftSoleThickness = (1.3 + (value ? Number.parseFloat(value) : 0)).toFixed(1)
        validateSoleThickness(state, soleNr)
        break
    case 'rightSoleThickness':
        state[soleNr].soleData.rightSoleThickness = value
        state[soleNr].soleData.totalRightSoleThickness = (1.3 + (value ? Number.parseFloat(value) : 0)).toFixed(1)
        validateSoleThickness(state, soleNr)
        break
    case 'copySole':
        state[soleNr].soleData = value
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeLeft, false, false)
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeRight, false, true)
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeLeft, true, false)
        validateSize(state, soleNr, state[soleNr].soleData.soleSizeRight, true, true)
        break
    case 'displayRightSole':
        state[soleNr].soleData[key] = value
        state[soleNr].soleData.rightSoleThickness = state[soleNr].soleData.leftSoleThickness
        state[soleNr].soleData.footSizeRight = state[soleNr].soleData.footSizeLeft
        state[soleNr].soleData.soleSizeRight = state[soleNr].soleData.soleSizeLeft
        state[soleNr].soleErrors.rightSoleThickness = false
        state[soleNr].soleErrors.footSizeRight = false
        state[soleNr].soleErrors.soleSizeRight = false
        break
    default:
        state[soleNr].soleData[key] = value
        break
    }

    validateAllFields(state, soleNr)
}

export const { closeSoles, soleData, soleAccordion, soleThickness, importSole, resetSole } = soleSpecification.actions
export default soleSpecification.reducer
