import React from 'react'
import { Button, FormLabel, Grid, Typography } from '@mui/material'
import useStyles from './style'
import { useSelector } from 'react-redux'
import LogoutIcon from '@mui/icons-material/Logout'
import { withStyles } from '@mui/styles'
import UserService from '../../lib/userService'

const TherapistDisplay = ({ classes }) => {
    const patient = useSelector((state) => state.general.patientData)

    /**
     * Logout function.
     */
    function logout () {
        UserService.doLogout()
    }

    return (
        <Grid container
            className={classes.container}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <Grid item xs={6}>
                <FormLabel>Behandelaar: </FormLabel>
                <Typography display={'inline'}
                    className={classes.therapistDisplay}>
                    {
                        // eslint-disable-next-line no-undef
                        process.env.NODE_ENV === 'production'
                            ? patient.therapist.name
                            : `DEV: ${patient.therapist.name}`
                    }
                </Typography>
            </Grid>
            <Button variant="contained" color='secondary' startIcon={<LogoutIcon/>}
                onClick={logout}>
                Uitloggen
            </Button>
        </Grid>
    )
}
export default withStyles(useStyles)(TherapistDisplay)
