import React from 'react'
import { Snackbar, Alert as MuiAlert } from '@mui/material'
import useStyles from './styles'
import { withStyles } from '@mui/styles'

const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const SnackbarComponentSuccess = ({ display, message, onClose }) => {
    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={display} autoHideDuration={6000} onClose={onClose}>
            <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default withStyles(useStyles)(SnackbarComponentSuccess)
