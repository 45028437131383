/* eslint-disable react/no-unknown-property */
import React from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { OBJLoader } from 'three-stdlib'
import * as TRHEE from 'three'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const BlobModelViewer = ({ objUrl1, objUrl2 }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>3D voorbeeld</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Canvas
                    style={{ width: '100%', height: '100vh' }}
                    camera={{ position: [1, 1, 2], fov: 50, zoom: 5 }}
                    shadows
                >
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[10, 10, 5]} intensity={1} castShadow />
                    <OrbitControls maxDistance={5}/>
                    <ObjModel url={objUrl1} position={new TRHEE.Vector3(0.06, 0, -0.07)} />
                    <ObjModel url={objUrl2} position={new TRHEE.Vector3(-0.06, 0, -0.07)} />
                </Canvas>

            </AccordionDetails>
        </Accordion>
    )
}

const ObjModel = ({ url, position }) => {
    // Load the OBJ model
    const obj = useLoader(OBJLoader, url)

    // Apply material and shading to the object
    obj.traverse((child) => {
        if (child.isMesh) {
            child.material = new TRHEE.MeshStandardMaterial({
                color: 0x7393B3, // Base color
                metalness: 0.2, // Metallic appearance
                roughness: 0.6 // Smoothness level
            })
            child.castShadow = true // Allow object to cast shadows
            child.receiveShadow = true // Allow object to receive shadows
        }
    })

    return <primitive object={obj} position={position} />
}

export default BlobModelViewer
