import moment from 'moment'
export const initialState = {
    orderType: '',
    patientData: {
        therapist: { name: '', email: '' },
        number: '',
        id: '',
        birthdate: moment(),
        lastName: '',
        gender: 'male',
        initials: '',
        fittingLocation: {
            id: 0,
            title: ''
        },
        receiveLocation: {
            id: 0,
            title: ''
        },
        emergencyOrder: false,
        prefferedSupport: 'male',
        linkedAppointment: null
    },
    generalAccordion: {
        accordion: true,
        validate: false,
        hasBeenOpened: true
    },
    generalErrors: {
        display: false,
        message: [],
        number: false,
        birthdate: false,
        lastName: false,
        fittingLocation: false,
        emergencyOrder: false,
        postponeOrder: false,
        gender: false
    }
}
