import JSZip from 'jszip'
import moment from 'moment'
import { base64ToBlob } from './util'
import api from './api'
import { action, actionType, benefit, benefitType, customer, histDataInfo, scaDataInfo, xml } from './data/ppcBasics'

let projectGuid = {
    leftHIST: '',
    rightHIST: '',
    leftSCA: '',
    rightSCA: '',
    project: ''
}

/**
 * Convert Unity OBJ-file to a PPC-file.
 *
 * This function processes JSON objects containing Unity results (base64-encoded OBJ files)
 * and patient information to generate a PPC file. It performs steps such as
 * creating ZIP folders, calling an API to get PS2D results,
 * and assembling SCA and HISTORY files into the final PPC file.
 *
 * @async
 * @function objToPPC
 * @param {Object} unityResult - JSON-object with base64-gecodeerde OBJ-files.
 * @param {Array<Object>} unityResult.Results - Array of results with data per sole.
 * @returns {Blob} PPC - A ZIP archive containing the complete PPC file.
 *
 * @throws {Error} Throws an error if an API call fails or a ZIP file cannot be generated correctly.
 *
 * @example
 * const unityResult = {
 *   Results: [
 *     { Sole: "base64string1" },
 *     { Sole: "base64string2" }
 *   ]
 * };
 * const patientInfo = {
 *   initials: "J.D.",
 *   gender: "male",
 *   lastName: "Doe",
 *   number: "12345",
 *   birthDate: "1990-01-01"
 * };
 *
 * const ppcFile = await objToPPC(unityResult, patientInfo);
 * console.log(ppcFile); // Blob with PPC-file
 */
async function objToPPC (unityResult, patientInfo) {
    const pmcZip = new JSZip()
    const project = createProjectJSON()

    for (let index = 0; index < unityResult.Results.length; index++) {
        // Create formdata
        const formData = {
            patient: {
                firstName: patientInfo.initials,
                gender: patientInfo.gender,
                lastName: patientInfo.lastName,
                number: patientInfo.number,
                birthDate: patientInfo.birthDate
            },
            insole: {
                isLeftFoot: index % 2 === 0,
                modelFileData: unityResult.Results[index].Sole
            }
        }

        if (index % 2 !== 0) {
            const sca = pmcZip.folder(`SCA-${projectGuid.rightSCA}`)
            const hist = pmcZip.folder(`HISTORY-${projectGuid.rightHIST}`)
            const ps2dResult = await api.testPS2D(formData)
            const result = base64ToBlob(ps2dResult.data, 'application/zip')

            const zip = await JSZip.loadAsync(result)
            for (const filename of Object.keys(zip.files)) {
                if (filename.includes('.sca')) {
                    const fileContent = await zip.file(filename).async('blob')
                    sca.file(`${projectGuid.rightSCA}.sca`, fileContent)
                    sca.file('dataInfo.json', JSON.stringify(dataInfo(projectGuid.rightSCA, 'sca')))
                }
            }
            hist.file('dataInfo.json', JSON.stringify(dataInfo(projectGuid.rightHIST, 'hist')))
            hist.file(`${projectGuid.rightHIST}.hist`, xml)
        } else {
            const sca = pmcZip.folder(`SCA-${projectGuid.leftSCA}`)
            const hist = pmcZip.folder(`HISTORY-${projectGuid.leftHIST}`)
            const ps2dResult = await api.testPS2D(formData)
            const result = base64ToBlob(ps2dResult.data, 'application/zip')

            const zip = await JSZip.loadAsync(result)
            for (const filename of Object.keys(zip.files)) {
                if (filename.includes('.sca')) {
                    const fileContent = await zip.file(filename).async('blob')
                    sca.file(`${projectGuid.leftSCA}.sca`, fileContent)
                    sca.file('dataInfo.json', JSON.stringify(dataInfo(projectGuid.leftSCA, 'sca')))
                }
            }
            hist.file('dataInfo.json', JSON.stringify(dataInfo(projectGuid.leftHIST, 'hist')))
            hist.file(`${projectGuid.leftHIST}.hist`, xml)
        }
    }

    pmcZip.file('project.json', JSON.stringify(project))
    pmcZip.file('action.json', JSON.stringify(action))
    pmcZip.file('actionType.json', JSON.stringify(actionType))
    pmcZip.file('benefit.json', JSON.stringify(benefit))
    pmcZip.file('benefitType.json', JSON.stringify(benefitType))
    const customCustomer = customer
    customCustomer.FamilyName = patientInfo.lastName
    customCustomer.firstName = patientInfo.number
    pmcZip.file('customer.json', JSON.stringify(customCustomer))

    const ppcZip = new JSZip()

    const today = moment().format('YYYY-MM-DD')
    ppcZip.file(`${today}_${patientInfo.lastName}_${patientInfo.number}_1.pmc`, await pmcZip.generateAsync({ type: 'blob' }))

    const zipContent = await ppcZip.generateAsync({ type: 'blob' })
    return zipContent
}

/**
 * Generate a datainfo file
 *
 * @param {GUID} id - Id linked to file
 * @returns {JSON} - content of the dataInfo file
 */
function dataInfo (id, type) {
    let result = {}
    switch (type) {
    case 'sca':
        result = scaDataInfo
        result.Id = id
        return result
    case 'hist':
        result = histDataInfo
        result.Id = id
        return result
    }
}

/**
 * Generate a UUID
 *
 * @returns {GUID}
 */
function uuidv4 () {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    )
}

/**
 * Generate a project file with GUIDs'
 *
 * @returns {JSON} projectJson
 */
function createProjectJSON () {
    projectGuid = {
        leftHIST: `{${uuidv4()}}`,
        rightHIST: `{${uuidv4()}}`,
        leftSCA: `{${uuidv4()}}`,
        rightSCA: `{${uuidv4()}}`,
        project: `{${uuidv4()}}`
    }
    return {
        Benefit: {
            Id: 'ODcqWQcBAAAAHgb/paW56EISl+Cm8vRv6e4='
        },
        Comment: 'BySLIM',
        CreatedLogically: moment().utc().format('YYYYMMDD[T]HHmmss.SSS[Z]'),
        Customer: {
            Id: 'ODcqWQcBAAAAHhSnR/fh9ECVkE0wcOghViQ='
        },
        Documents: [
            {
                Id: projectGuid.leftHIST,
                Role: 'eDocumentRole::HISTORY'
            },
            {
                Id: projectGuid.leftSCA,
                Role: 'eDocumentRole::SCA'
            },
            {
                Id: projectGuid.rightHIST,
                Role: 'eDocumentRole::HISTORY'
            },
            {
                Id: projectGuid.rightSCA,
                Role: 'eDocumentRole::SCA'
            }
        ],
        Id: projectGuid.project,
        ModifiedLogically: moment().utc().format('YYYYMMDD[T]HHmmss.SSS[Z]'),
        Settings: {
            AdditionalInfo: {
            },
            BaseInfo: {
                Left: {
                    His: projectGuid.leftHIST,
                    Sca: projectGuid.leftSCA
                },
                Right: {
                    His: projectGuid.rightHIST,
                    Sca: projectGuid.rightSCA
                }
            },
            Company: 'Scharenborg',
            DiffInfo: [
            ],
            LeftInfo: {
                AdditionalInfo: {
                },
                Blank: {
                    Id: '',
                    TypeString: ''
                },
                Comment: '',
                Holder: {
                    Id: '',
                    List: [
                        ''
                    ]
                }
            },
            Responsible: 'SLIM',
            RightInfo: {
                AdditionalInfo: {
                },
                Blank: {
                    Id: '',
                    TypeString: ''
                },
                Comment: '',
                Holder: {
                    Id: '',
                    List: [
                        ''
                    ]
                }
            },
            ShippingNotice: ''
        },
        Side: 'Side::UNKNOWN',
        Type: {
            Name: 'http://api.schemas.cadenas.de/pcs/documents/projects/milling',
            SubType: '',
            Version: '7.0'
        },
        Version: '1.0'
    }
}

export { objToPPC }
