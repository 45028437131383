import React from 'react'
import { ThemeProvider as MuiThemeProvider, Container, Box } from '@mui/material'
import './style.css'
import CssBaseline from '@mui/material/CssBaseline'
import { useSelector } from 'react-redux'

// pages
import Overview from './OverviewPage/index'

// Styling
import useStyles from './style'
import { Routes, Route } from 'react-router-dom'
import HomePage from './HomePage'
import InsolePage from './InsolePage'
import FitsPage from './FitsPage'
import AppTitle from '../components/AppTitle'
import { LOGOUT_ENDPOINT } from '../env'
import ThemeProvider from '../lib/themeProvider'
import DataProvider from '../lib/dataProvider'
import { withStyles } from '@mui/styles'

/**
 * Logout function
 *
 * @return null
 */
function Logout () {
    window.location.replace(LOGOUT_ENDPOINT)
    return null
}

const App = () => {
    const other = useSelector((state) => state.other)
    const theme = ThemeProvider.theme(other.backend)
    DataProvider.setFolder(other.dataProvider)

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Container >
                <Box>
                    <AppTitle/>
                    <Routes>
                        <Route path="/:number/:environment" element={<HomePage />} />
                        <Route path="/:number" element={<HomePage />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="/insole" element={<InsolePage />} />
                        <Route path="/fits" element={<FitsPage />} />
                        <Route path="/order" element={<Overview />} />
                        <Route path="/logout" element={<Logout/>}/>
                    </Routes>
                </Box>
            </Container>
        </MuiThemeProvider>
    )
}

export default withStyles(useStyles)(App)
