import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateNumber, validateLastName, validateBirthdate, validateGender, validateFittingLocation, validateAllFields } from './validation.js'
import moment from 'moment'

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        patientData (state, action) {
            switch (action.payload.key) {
            case 'therapist':
                state.patientData.therapist = action.payload.value
                break
            case 'number':
                state.patientData.number = action.payload.value
                validateNumber(state, action.payload.value)
                break
            case 'postponeOrder':
                state.patientData.postponeOrder = action.payload.value
                break
            case 'birthdate':
                state.patientData.birthdate = action.payload.value
                validateBirthdate(state, action.payload.value)
                break
            case 'lastName':
                state.patientData.lastName = action.payload.value
                validateLastName(state, action.payload.value)
                break
            case 'gender':
                state.patientData.gender = action.payload.value
                validateGender(state, action.payload.value)
                break
            case 'fittingLocation':
                state.patientData.fittingLocation = action.payload.value
                validateFittingLocation(state, action.payload.value)
                validateAllFields(state)
                break
            case 'receiveLocation':
                state.patientData.receiveLocation = action.payload.value
                validateAllFields(state)
                break
            case 'emergencyOrder':
                state.patientData.emergencyOrder = action.payload.value
                validateAllFields(state)
                break
            case 'prefferedSupport':
                state.patientData.prefferedSupport = action.payload.value
                break
            case 'id':
                state.patientData.id = action.payload.value
                break
            case 'initials':
                state.patientData.initials = action.payload.value
                break
            case 'linkedAppointment':
                state.patientData.linkedAppointment = action.payload.value
                break
            default:
                state.patientData = action.payload
                break
            }
        },
        resetPatientData (state) {
            state.patientData.birthdate = moment()
            state.patientData.initials = ''
            state.patientData.gender = 'male'
            state.patientData.id = ''
            state.patientData.lastName = ''
            state.patientData.initials = ''
            state.patientData.prefferedSupport = 'male'
            state.patientData.linkedAppointment = null
        },
        setOrderType (state, action) {
            state.orderType = action.payload
        },
        generalAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                state.generalAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.generalAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.generalAccordion.hasBeenOpened = true
                break
            }
        },
        setError (state, action) {
            state.generalErrors.message.push(action.payload.message)
        },
        closeGeneral (state) {
            state.generalAccordion.accordion = false
            state.generalAccordion.hasBeenOpened = true
            validateAllFields(state)
        }
    }
})

export const { patientData, generalAccordion, closeGeneral, setError, setOrderType, resetPatientData } = generalSlice.actions
export default generalSlice.reducer
