import React from 'react'
import { Grid, Typography } from '@mui/material'
import useStyles from './style'
import { withStyles } from '@mui/styles'
import { useSelector } from 'react-redux'


const AppTitle = ({ classes }) => {
    const other = useSelector((state) => state.other)

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.titleContainer}
        >
            <Typography className={classes.ApplicationTitle} variant="h2" component="h2">SLIM modelleren</Typography>
            <Typography variant="subtitle" component="p">{other.backend.key}</Typography>
        </Grid>
    )
}

export default withStyles(useStyles)(AppTitle)
