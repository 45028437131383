/**
 * diagnose data provider
 * @version 1.0.0
 */
class diagnose {
    /**
     * Get a list with diagnoses
     */
    get diagnoses () {
        return [
            {
                id: 1,
                name: 'Accessoir os naviculare syndroom',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 2,
                name: 'Achillespees tendinopathie',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 21
            },
            {
                id: 3,
                name: 'Adductoren tendinopathie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 4,
                name: 'Anterieur enkel impingement syndroom (AEIS)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 5,
                name: 'Anticus Logesyndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 6,
                name: 'Apophysitis calcaneus/Morbus Sever',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'true',
                soleTherapy: 16
            },
            {
                id: 7,
                name: 'Artritis BSG',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 12
            },
            {
                id: 8,
                name: 'Artritis psoriatica',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 9,
                name: 'Artrose BSG',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 10,
                name: 'Artrose MTP-1',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 11,
                name: 'Artrose MTP-gewricht',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 12,
                name: 'Artrose TMT-1',
                MICPosition: 'TMT - 1',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 13,
                name: 'A-specifieke rugklachten',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 14,
                name: 'Avasculaire femurkopnecrose',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 15,
                name: 'Avulsie fractuur basis metatarsale 5',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                PPSI: 'Eigen keuze invullen',
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 16,
                name: 'Baker\'s cyste',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 17,
                name: 'Bandirritatie lig. collaterale laterale',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 18,
                name: 'Bandirritatie lig. collaterale mediale',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 19,
                name: 'Baxter\'s nerve entrapment',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 20,
                name: 'Beenlengteverschil',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 21,
                name: 'Bursitis calcanei plantaris',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 22,
                name: 'Bursitis iliopectinea',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 23,
                name: 'Bursitis infrapattelaris',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 24,
                name: 'Bursitis ischiadica',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 25,
                name: 'Bursitis lig. collaterale mediale',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 26,
                name: 'Bursitis MTP-1',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 27,
                name: 'Bursitis MTP-5',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 28,
                name: 'Bursitis pes anserinus superficialis',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 29,
                name: 'Bursitis prepatellaris',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 30,
                name: 'Bursitis retrocalcaneale',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 31,
                name: 'Bursitis subcutane achillei',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 32,
                name: 'Bursitis subtendinae achillei',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 33,
                name: 'Bursitis subtrochanterica',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 34,
                name: 'Bursitis suprapatellaris',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 35,
                name: 'Calcaneus stressfractuur',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'true',
                soleTherapy: 16
            },
            {
                id: 36,
                name: 'Callus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 37,
                name: 'Capsulitis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 38,
                name: 'Charcot voet',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 39,
                name: 'Chronische compartimentsyndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 40,
                name: 'Chronische enkelinstabiliteit',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 11
            },
            {
                id: 41,
                name: 'Claudicatio intermittens',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 42,
                name: 'Clavus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 18
            },
            {
                id: 43,
                name: 'Compressieneuropathie n. femoralis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 12
            },
            {
                id: 44,
                name: 'Compressieneuropathie n. interdigitalis pedis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 12
            },
            {
                id: 45,
                name: 'Compressieneuropathie n. ischiadicus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 12
            },
            {
                id: 46,
                name: 'Compressieneuropathie n. peroneus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 12
            },
            {
                id: 47,
                name: 'Compressieneuropathie n. plantaris',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 12
            },
            {
                id: 48,
                name: 'Compressiesyndroom a. poplitea',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 49,
                name: 'Corpus liberum',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 50,
                name: 'Diagnose onduidelijk',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 51,
                name: 'Diagnose ontbreekt in lijst',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 52,
                name: 'Discopathie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 53,
                name: 'Duchenne',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 54,
                name: 'Duckbill fractuur',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 55,
                name: 'Ehlers-Danlos syndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 56,
                name: 'Epifysiolyse capitis femoris',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 57,
                name: 'Eversietrauma',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 58,
                name: 'Fasciitis plantaris',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'true',
                ascPad: 'false',
                soleTherapy: 22
            },
            {
                id: 59,
                name: 'Fasciosis plantaris',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'true',
                ascPad: 'false',
                soleTherapy: 21
            },
            {
                id: 60,
                name: 'Fat pad syndroom',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'true',
                soleTherapy: 22
            },
            {
                id: 61,
                name: 'Femoro-acetabulair impingement (FAI)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 62,
                name: 'Fenomeen van Raynaud',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 63,
                name: 'Fibromyalgie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 64,
                name: 'Fractuur fibula',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 11
            },
            {
                id: 65,
                name: 'Ganglion',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 66,
                name: 'Haglundse exostose',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 21
            },
            {
                id: 67,
                name: 'Hallux limitus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 68,
                name: 'Hallux rigidus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 69,
                name: 'Hallux valgus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 70,
                name: 'Hamerstand digiti',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 71,
                name: 'Hamstring tendinopathie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 72,
                name: 'Hernia nucleus pulposi',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 73,
                name: 'Heupartrose',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 74,
                name: 'Heuter\'s neuralgie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: 'Intermetatarsale pelotte 1-2',
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 75,
                name: 'HMSN / Charcot-Marie-Tooth',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: 6,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 76,
                name: 'Hoffitis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 77,
                name: 'Houser\'s neuralgie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: 'Intermetatarsale pelotte 2-3',
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 78,
                name: 'Hyperconvexe Nagel',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 79,
                name: 'Hypertonie m. plantaris',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 11
            },
            {
                id: 80,
                name: 'Iliopsoas syndroom',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 81,
                name: 'Insertietendinopathie pes anserinus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 82,
                name: 'Interdigitale clavus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 83,
                name: 'Inversietrauma',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 11
            },
            {
                id: 84,
                name: 'Ischemische ulcera',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 85,
                name: 'Islen\'s neuralgie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: 'Intermetatarsale pelotte 4-5',
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 86,
                name: 'Jicht',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 87,
                name: 'Jones fractuur',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 88,
                name: 'Joplin\'s neuralgie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 89,
                name: 'Jumper\'s knee',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 90,
                name: 'Klachtenvrij',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 91,
                name: 'Klapvoet',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 92,
                name: 'Klauwstand digiti',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 93,
                name: 'Laterale gonartrose',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 94,
                name: 'Letsel lig. calcaneonaviculaire plantaire',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 95,
                name: 'Letsel lig. cruciatum anterius',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 96,
                name: 'Letsel lig. cruciatum posterius',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 97,
                name: 'Letsel lig. metatarsale transversum profundum',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 98,
                name: 'Lumbale radiculopathie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 99,
                name: 'Luxatie / subluxatie digiti',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 100,
                name: 'Luxatie / subluxatie MTP',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 101,
                name: 'Mallet toe',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 102,
                name: 'Marsfractuur',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 103,
                name: 'Mediaal tibiaal stress syndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 11
            },
            {
                id: 104,
                name: 'Mediale gonartrose',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: 6,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 105,
                name: 'Meniscusleasie',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 106,
                name: 'Meralgia paraesthetica',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 107,
                name: 'Metatarsus primus varus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 108,
                name: 'Morbus Köhler 1 (Köhler)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 109,
                name: 'Morbus Köhler 2 (Freiberg)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 110,
                name: 'Morbus Ledderhose',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 111,
                name: 'Morbus Osgood-Schlatter / Apophysitis tibialis adolescentium',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 112,
                name: 'Mortonse neuralgie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: 'Intermetatarsale pelotte 3-4',
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 113,
                name: 'Neuro-ischemisch ulcera',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 114,
                name: 'Neuropatisch ulcera',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 115,
                name: 'Onychogryphosis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 116,
                name: 'Onycholysis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 117,
                name: 'Onychomycosis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 118,
                name: 'Os peroneum',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 119,
                name: 'Osteochondritis dissecans enkel',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 120,
                name: 'Osteochondritis dissecans heup / Morbus Legg-Calvé-Perthes',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 121,
                name: 'Osteochondritis dissecans knie / Morbus König',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 122,
                name: 'Osteochondritis dissecans talus',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 123,
                name: 'Osteoid / Osteoom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 124,
                name: 'Osteomyelitis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 125,
                name: 'Patella luxatie',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 126,
                name: 'Patellofemoraal pijnsyndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 127,
                name: 'Perifeer arterieel vaatlijden',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 128,
                name: 'Persiosis / Perniones',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 129,
                name: 'Piriformis syndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 130,
                name: 'Plica syndroom',
                MICPosition: 'Os naviculare',
                ASC: 5,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 131,
                name: 'Poly articulaire artrose',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 132,
                name: 'Posterieur enkel impingement syndroom (PEIS)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 133,
                name: 'Pseudo radiculair pijnsyndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 134,
                name: 'Pseudojicht',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 135,
                name: 'Radiculair pijnsyndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 136,
                name: 'Reumatoïde artritis (actief)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 137,
                name: 'Reumatoïde artritis (chronisch)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 138,
                name: 'Ruptuur lig. patellae',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 139,
                name: 'Ruptuur lig. plantare longum',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 140,
                name: 'Ruptuur m. tibialis anterior',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 141,
                name: 'Sesamoïditis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA25: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 18
            },
            {
                id: 142,
                name: 'Sinding-Larson-Johansson Syndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 143,
                name: 'Sinus tarsi syndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 144,
                name: 'Snapping hip',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 145,
                name: 'Spina calcanei (hielspoor)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 146,
                name: 'Spondylartrose',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 147,
                name: 'Stressfractuur digiti',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 148,
                name: 'Stressfractuur os Naviculare',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 149,
                name: 'Stressfractuur sesamoïdea',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 150,
                name: 'Subunguaal hematoom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 151,
                name: 'Supra- / infraductie van digiti',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 152,
                name: 'Surmenage m. gastrocnemicus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 153,
                name: 'Surmenage m. quadriceps femoris',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 154,
                name: 'Syndroom van Pellegrini-Stieda',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 155,
                name: 'Synovitis MTP-gewrichten',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 156,
                name: 'Systemische sclerodermie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 157,
                name: 'Tailor\'s bunion',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 158,
                name: 'Talus fractuur',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 159,
                name: 'Tarsaal tunnelsyndroom (achter)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 160,
                name: 'Tarsaal tunnelsyndroom (voor)',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 161,
                name: 'Tarsale coalitie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 162,
                name: 'Tendinopathie m. extensor digitorum longus/brevis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 163,
                name: 'Tendinopathie m. extensor hallicis longus/brevis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 164,
                name: 'Tendinopathie m. Peroneus brevis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: 6,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 165,
                name: 'Tendinopathie m. Peroneus longus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: 6,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 166,
                name: 'Tendinopathie m. Tibialis anterior',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 167,
                name: 'Tendinopathie m. Tibialis posterior',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 21
            },
            {
                id: 168,
                name: 'Tibia fractuur',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 169,
                name: 'Tractus iliotibialis frictie syndroom',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 16
            },
            {
                id: 170,
                name: 'Turf toe',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA25: 'Eigen keuze invullen',
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 171,
                name: 'Unguis incarnatus',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 172,
                name: 'Varus-adductusstand digiti',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: 5,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 17
            },
            {
                id: 173,
                name: 'Verrucae plantaris',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 5
            },
            {
                id: 174,
                name: 'Zandteen / Volleybalteen',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 175,
                name: 'Ziekte van Bechterew / Spondylitis ankylopoetica',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 176,
                name: 'Ziekte van Parkinson',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 177,
                name: 'Zwanenhals deformiteit',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 178,
                name: 'Neuropathie',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 1
            },
            {
                id: 179,
                name: 'Systemische Lupus Erythematodes',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 0
            },
            {
                id: 180,
                name: 'Tendinopathie m. flexor digitorum longus/brevis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: null,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 1
            },
            {
                id: 181,
                name: 'Tendinopathie m. flexor hallucis longus/brevis',
                MICPosition: 'Os naviculare',
                ASC: 2,
                CV: 10,
                MIC: 26,
                pelotte: 1,
                pelotteType: null,
                PPSI: null,
                PT: null,
                RCTB: null,
                SA15: null,
                SPSI: null,
                fasciPad: 'false',
                ascPad: 'false',
                soleTherapy: 1
            }
        ]
    }

    /**
     * get the diagnose by id.
     *
     * @param {Number} id - id;
     * @return {Object} complete diagnose.
     */
    getDiagnoseById (id) {
        const diagnoses = this.diagnoses
        return diagnoses.find((object) => object.id === id)
    }

    /**
     * get the diagnose by name.
     *
     * @param {String} name - name;
     * @return {Object} complete diagnose.
     */
    getDiagnoseByName (name) {
        const diagnoses = this.diagnoses
        return diagnoses.find((object) => object.name === name)
    }

    /**
     * get the diagnose by name.
     *
     * @param {String} name - name;
     * @return {Object} complete diagnose.
     */
    getClosestsDiagnosis (name) {
        const diagnoses = this.diagnoses
        return diagnoses.find((object) => object.name.includes(name))
    }
}

 
export default (new diagnose())
