import React, { useEffect, useState } from 'react'
import useStyles from './style'
import TherapistDisplay from '../../components/TherapistDisplay'
import General from '../../tabs/general'
import Diagnose from '../../tabs/diagnosis'
import SoleResult from '../../tabs/soleResult'
import FitsSpecifications from '../../tabs/fitsSpecificaties'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ValidationErrorDialog from '../../components/ValidationErrorDialog'
import NavigationButtons from '../../components/NavigationButtons'
import { withStyles } from '@mui/styles'
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

const FitsPage = () => {
    const navigate = useNavigate()

    const { generalErrors, orderType } = useSelector(state => state.general)
    const { diagnosisData, diagnosisErrors } = useSelector((state) => state.diagnosis)
    const fitsSpecificationTabs = useSelector(state => state.fitsSpecification)
    const other = useSelector(state => state.other)

    const fitsSpecificationsMessageError = fitsSpecificationTabs.reduce((previousValue, currentValue) => previousValue || currentValue.fitsErrors.message, false)
    const [generalSnackbar, setGeneralSnackbar] = useState([])
    const [diagnosisSnackbar, setDiagnosisSnackbar] = useState([])
    const [fitsSpecSnackbar, setFitsSpecSnackbar] = useState([])

    useEffect(() => {
        if (orderType === '') {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (other.allValid) {
            navigate('/order')
        }
    }, [other.allValid, other.errorDialog])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setGeneralSnackbar, generalSnackbar, generalErrors.message)
    }, [generalErrors.message])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setDiagnosisSnackbar, diagnosisSnackbar, diagnosisErrors.message)
    }, [diagnosisErrors.message])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setFitsSpecSnackbar, fitsSpecSnackbar, fitsSpecificationsMessageError)
    }, [fitsSpecificationsMessageError])

    /**
     * Update snackbar function
     *
     * @param {*} setSnackbarArray - useState snackbar array function
     * @param {*} snackbarArray  - useState snackbar array data
     * @param {*} messages - redux messages array
     */
    function updateSnackbar (setSnackbarArray, snackbarArray, messages) {
        const action = snackbarId => (
            <IconButton onClick={() => { closeSnackbar(snackbarId) }}>
                <Close sx={{ color: '#fff' }} />
            </IconButton>
        )
        // loop throug open errors
        snackbarArray.forEach(error => {
            if (!messages.includes(error.value)) {
                // message is not in the reducer so remove the message from the generalsnackbar
                closeSnackbar(error.key)
                const newArr = snackbarArray.filter(e => e.key !== error.key)
                setSnackbarArray(newArr)
            }
        })

        // loop through new errors
        messages.forEach(message => {
            // if error is not in open errors create a new one.
            if (snackbarArray.find((error) => error.value === message) === undefined) {
                const genArray = snackbarArray
                const id = enqueueSnackbar({
                    message,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: null,
                    variant: 'error',
                    preventDuplicate: true,
                    action
                })

                genArray.push({ value: message, key: id })
                setSnackbarArray(genArray)
            }
        })
    }
    /**
     * Show as many FitsPage options as selected. For now it is not needed since by default we will only order one.
     * @returns Forms for every set number of FITS to order
     */
    function renderFitsSpecificationsTabs () {
        const fitsSpecificationsTabs = []
        for (let i = 0; i < diagnosisData.numberOfSoles; i++) {
            fitsSpecificationsTabs.push(<FitsSpecifications key={i} fitsSpecNumber={i} />)
        }
        return fitsSpecificationsTabs
    }

    /**
     * Show as many sole options as selected. For now it is not needed since by default we will only order one.
     * @return {JSX} - a map of JSX solespecification.
     */
    function renderSoleResultTabs () {
        const soleResTabs = []
        for (let i = 0; i < diagnosisData.numberOfSoles; i++) {
            soleResTabs.push(<SoleResult key={i} soleResNumber={i} />)
        }
        return soleResTabs
    }

    return (
        <div>
            <TherapistDisplay />
            <General />
            <Diagnose />
            { renderFitsSpecificationsTabs() }
            { renderSoleResultTabs() }
            <SnackbarProvider />
            <ValidationErrorDialog />
            <NavigationButtons />
        </div>
    )
}

export default withStyles(useStyles)(FitsPage)
